import { type SliderRootSlotProps } from '@mui/base'
import { clsx } from 'clsx'
import { forwardRef } from 'react'

export const SliderRoot = forwardRef<HTMLSpanElement, SliderRootSlotProps>(
  function SliderRoot(props, ref) {
    const {
      children,
      className,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- The variable is not needed for rendering, otherwise it will be provided in `rest` prop and appear on HTML element as `ownerState="[object Object]"`
      ownerState,
      ...rest
    } = props

    return (
      <span className={clsx('slider-root', className)} ref={ref} {...rest}>
        <span className="slider-root__container">{children}</span>
      </span>
    )
  },
)
